import server from './server'

/**
 * @description: login
 * @param {string} username 
 * @param {string} password 
 * @return {Promise} promise
 * */
export const login = (data) => {
  return server({
    url: '/admin/login/ ',
    method: 'POST',
    data
  })
}
/**
* @description: account list
* @param {string} search 
* @param {string} ordering 
* @param {number} page
* @param {number} page_size
* @return {Promise} promise
* */
export const accountList = (params) => {
  return server({
    url: '/admin/user/',
    method: 'GET',
    params
  })
}
/**
* @description: account deatil
* @return {Promise} promise
* */
export const accountDetail = (id) => {
  return server({
    url: `/admin/user/${id}/`,
    method: 'GET',
  })
}
/**
* @description: account create
* @param {string} username
* @param {string} password
* @param {number} role 1 2 3
* @return {Promise} promise
* */
export const accountCreate = (data) => {
  return server({
    url: '/admin/user/',
    method: 'POST',
    data
  })
}
/**
* @description: account delete
* @param {string} id
* @return {Promise} promise
* */
export const accountDelete = (params) => {
  return server({
    url: '/admin/user/',
    method: 'DELETE',
    params
  })
}
/**
* @description: account edit
* @param {string} password
* @param {number} active  0  1
* @param {number} role 1 2 3
* @return {Promise} promise
* */
export const accountEdit = ({id,...data}) => {
  return server({
    url: `/admin/user/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
* @description: serviceTerm list
* @return {Promise} promise
* */
export const serviceTermList = () => {
  return server({
    url: `/admin/service_term/`,
    method: 'GET',
  })
}
/**
* @description: serviceTerm detail
* @return {Promise} promise
* */
export const serviceTermDetail = (id) => {
  return server({
    url: `/admin/service_term/${id}/`,
    method: 'GET'
  })
}
/**
* @description: serviceTerm edit
* @param {string} title 
* @param {string} content
* @return {Promise} promise
* */
export const serviceTermEdit = ({id,...data}) => {
  return server({
    url: `/admin/service_term/${id}/`,
    method: 'PUT',
    data
  })
}
/**
* @description: notice create
* @param {string} title 
* @param {string} content
* @param {number} order
* @return {Promise} promise
* */
export const noticeCreate = (data) => {
  return server({
    url: `/admin/notice/`,
    method: 'POST',
    data
  })
}
/**
* @description: notice detail
* @return {Promise} promise
* */
export const noticeDetail = (id) => {
  return server({
    url: `/admin/notice/${id}/`,
    method: 'GET'
  })
}
/**
* @description: notice list
* @param {number} page
* @param {number} page_size
* @param {string} search
* @param {string} ordering
* @param {number} active 0 1
* @param {number} is_home 0 1
* @return {Promise} promise
* */
export const noticeList = (params) => {
  return server({
    url: `/admin/notice/`,
    method: 'GET',
    params
  })
}
/**
* @description: notice edit
* @param {number} title
* @param {number} content
* @param {number} order
* @param {number} active 0show 1hide
* @return {Promise} promise
* */
export const noticeEdit = ({id,...data}) => {
  return server({
    url: `/admin/notice/${id}/`,
    method: 'PUT',
    data
  })
}
/**
* @description: notice delete
* @return {Promise} promise
* */
export const noticeDelete = (id) => {
  return server({
    url: `/admin/notice/${id}/`,
    method: 'DELETE'
  })
}
/**
 * @description: FAQ list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const getFAQ = (params) => {
  return server({
    url: `/admin/faq/`,
    method: 'GET',
    params
  })
}
/**
 * @description: FAQ delete
 * @return {Promise} promise
 * */
export const deleteFAQ = (id) => {
  return server({
    url: `/admin/faq/${id}/`,
    method: 'DELETE'
  })
}
/**
 * @description: FAQ detail
 * @return {Promise} promise
 * */
export const getFAQDetail = (id) => {
  return server({
    url: `/admin/faq/${id}/`,
    method: 'GET'
  })
}
/**
 * @description: FAQ add
 * @return {Promise} promise
 * */
export const addFAQ = (data) => {
  return server({
    url: `/admin/faq/`,
    method: 'POST',
    data
  })
}
/**
 * @description: FAQ edit
 * @return {Promise} promise
 * */
export const editFAQ = ({id,...data}) => {
  return server({
    url: `/admin/faq/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: QA list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} status 0 1
 * @return {Promise} promise
 * */
export const getQA = (params) => {
  return server({
    url: `/admin/qa/`,
    method: 'GET',
    params
  })
}
/**
 * @description: QA detail
 * @return {Promise} promise
 * */
export const getQADetail = (id) => {
  return server({
    url: `/admin/qa/${id}/`,
    method: 'GET'
  })
}
/**
 * @description: QA edit
 * @param {string} answer
 * @return {Promise} promise
 * */
export const editQA = ({id,...data}) => {
  return server({
    url: `/admin/qa/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
 * @description: QA delete
 * @return {Promise} promise
 * */
export const deleteQA = (id) => {
  return server({
    url: `/admin/qa/${id}/`,
    method: 'DELETE'
  })
}
/**
 * @description: top qa
 * @param {number} id
 * @param {number} is_top 0 1
 * @return {Promise} promise
 * */
 export const topQA = (data) => {
  return server({
    url: `/admin/qa/is_top/`,
    method: 'POST',
    data
  })
}
/**
 * @description: wrong list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @return {Promise} promise
 * */
 export const getWrong = (params) => {
  return server({
    url: `/admin/wrong`,
    method: 'GET',
    params
  })
}
/**
 * @description: wrong detail
 * @return {Promise} promise
 * */
 export const getWrongDetail = (id) => {
  return server({
    url: `/admin/wrong/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: wrong delete
 * @return {Promise} promise
 * */
 export const wrongDelete = (id) => {
  return server({
    url: `/admin/wrong/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: top wrong
 * @param {number} id
 * @param {number} is_top 0 1
 * @return {Promise} promise
 * */
 export const topWrong = (data) => {
  return server({
    url: `/admin/wrong/is_top/`,
    method: 'POST',
    data
  })
}
/**
 * @description: wrong question
 * @param {string} answer
 * @return {Promise} promise
 * */
 export const wrongQuestion = ({id,...data}) => {
  return server({
    url: `/admin/wrong/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
 * @description: carousel list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @return {Promise} promise
 * */
 export const carouselList = (params) => {
  return server({
    url: `/admin/banner/`,
    method: 'GET',
    params
  })
}
/**
 * @description: carousel add
 * @param {string} title
 * @param {string} image
 * @param {string} link jump to
 * @param {number} order
 * @return {Promise} promise
 * */
export const carouselAdd = (data) => {
  return server({
    url: `/admin/banner/`,
    method: 'POST',
    data
  })
}
/**
 * @description: carousel detail
 * @return {Promise} promise
 * */
export const carouselDetail = (id) => {
  return server({
    url: `/admin/banner/${id}/`,
    method: 'GET'
  })
}
/**
 * @description: carousel change
 * @param {string} title
 * @param {string} image
 * @param {string} link jump to
 * @param {number} order
 * @param {number} active 0hide 1show
 * @return {Promise} promise
 * */
export const carouselChange = ({id,...data}) => {
  return server({
    url: `/admin/banner/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
 * @description: carousel delete
 * @return {Promise} promise
 * */
export const carouselDelete = (id) => {
  return server({
    url: `/admin/banner/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: pass list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @return {Promise} promise
 * */
 export const passList = (params) => {
  return server({
    url: `/admin/pass/`,
    method: 'GET',
    params
  })
}
/**
 * @description: pass add
 * @param {number} status 0 1
 * @param {string} username
 * @param {string} subject
 * @param {array} content [{row:string,size:0 1}]
 * @param {string} pass_url
 * @return {Promise} promise
 * */
export const passAdd = (data) => {
  return server({
    url: `/admin/pass/`,
    method: 'POST',
    data
  })
}
/**
 * @description: pass detail
 * @return {Promise} promise
 * */
export const passDetail = (id) => {
  return server({
    url: `/admin/pass/${id}/`,
    method: 'GET'
  })
}
/**
 * @description: pass edit
 * @param {number} status 0 1
 * @param {string} username
 * @param {string} subject
 * @param {array} content [{row:string,size:0 1}]
 * @param {string} pass_url
 * @return {Promise} promise
 * */
export const passEdit = ({id,...data}) => {
  return server({
    url: `/admin/pass/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: pass delete
 * @return {Promise} promise
 * */
export const passDelete = (id) => {
  return server({
    url: `/admin/pass/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: member list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} is_library 0 1
 * @param {number} is_publish 0 1
 * @param {number} active 0 1
 * @return {Promise} promise
 * */
export const memberList = (params) => {
  return server({
    url: '/admin/member/',
    method: 'GET',
    params
  })
}
/**
 * @description: change member username
 * @param {string} username
 * @return {Promise} promise
 * */
export const changeMemberUsername = ({id,...data}) => {
  return server({
    url: `/admin/member/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
 * @description: member detail
 * @return {Promise} promise
 * */
export const memberDetail = (id) => {
  return server({
    url: `/admin/member/${id}/`,
    method: 'GET'
  })
}
/**
 * @description: member change
 * @param {string} password
 * @param {number} active 0hide 1show
 * @return {Promise} promise
 * */
export const memberChange = ({id,...data}) => {
  return server({
    url: `/admin/member/${id}/`,
    method: 'PATCH',
    data
  })
}
/**
 * @description: member add
 * @param {string} password
 * @param {string} real_name
 * @param {string} username
 * @return {Promise} promise
 * */
 export const addMember = (data) => {
  return server({
    url: `/admin/member/`,
    method: 'POST',
    data
  })
}
/**
 * @description: member edit
 * @param {string} real_name
 * @param {string} username
 * @return {Promise} promise
 * */
 export const editMember = ({id,...data}) => {
  return server({
    url: `/admin/member/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: member delete
 * @return {Promise} promise
 * */
export const memberDelete = (id) => {
  return server({
    url: `/admin/member/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: member finish history
 * @param {number} page
 * @param {number} page_size
 * @param {number} user_id
 * @return {Promise} promise
 * */
 export const finishHistory = (params) => {
  return server({
    url: `/admin/finishhistory/`,
    method: 'GET',
    params
  })
}
/**
 * @description: member score
 * @param {number} page
 * @param {number} page_size
 * @param {number} user
 * @param {number} type 1add 2reduce
 * @return {Promise} promise
 * */
 export const memberScore = (params) => {
  return server({
    url: `/admin/score/`,
    method: 'GET',
    params
  })
}
/**
 * @description: service introduce
 * @return {Promise} promise
 * */
 export const getServiceIntroduce = (id) => {
  return server({
    url: `/admin/service/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: service introduce edit
 * @param {string} content
 * @return {Promise} promise
 * */
export const editServiceIntroduce = ({id,...data}) => {
  return server({
    url: `/admin/service/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: exam type list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} is_hot 0 1
 * @return {Promise} promise
 * */
 export const examTypeList = (params) => {
  return server({
    url: `/admin/position/`,
    method: 'GET',
    params
  })
}
/**
 * @description: exam type detail
 * @return {Promise} promise
 * */
 export const examTypeDetail = (id) => {
  return server({
    url: `/admin/position/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: exam type create
 * @param {string} title
 * @param {number} status 0 1
 * @param {number} order
 * @param {number} is_hot 0 1
 * @return {Promise} promise
 * */
 export const createExamType = (data) => {
  return server({
    url: `/admin/position/`,
    method: 'POST',
    data
  })
}
/**
 * @description: exam type edit
 * @param {string} title
 * @param {number} status 0 1
 * @param {number} order
 * @param {number} is_hot 0 1
 * @return {Promise} promise
 * */
 export const editExamType = ({id,...data}) => {
  return server({
    url: `/admin/position/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: exam type delete
 * @return {Promise} promise
 * */
 export const deleteExamType = (id) => {
  return server({
    url: `/admin/position/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: subject list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} position  position id
 * @param {number} is_hot  0 1
 * @return {Promise} promise
 * */
 export const subjectList = (params) => {
  return server({
    url: `/admin/subject/`,
    method: 'GET',
    params
  })
}
/**
 * @description: subject detail
 * @return {Promise} promise
 * */
 export const subjectDetail = (id) => {
  return server({
    url: `/admin/subject/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: subject create
 * @param {string} title
 * @param {number} position_id  position id
 * @param {number} is_hot: 0 1
 * @param {number} price
 * @param {string} time
 * @param {number} status 0 1
 * @param {number} order
 * @param {string} cover
 * @param {string} content_desc
 * @param {array} content  {title:string,desc:string,service:string,price:number,days:number}
 * @return {Promise} promise
 * */
 export const createSubject = (data) => {
  return server({
    url: `/admin/subject/`,
    method: 'POST',
    data
  })
}
/**
 * @description: subject edit
 * @param {string} title
 * @param {number} position_id  position id
 * @param {number} is_hot: 0 1
 * @param {number} price
 * @param {string} time
 * @param {number} status 0 1
 * @param {number} order
 * @param {string} cover
 * @param {string} content_desc
 * @param {array} content  {title:string,desc:string,service:string,price:number,days:number}
 * @return {Promise} promise
 * */
 export const editSubject = ({id,...data}) => {
  return server({
    url: `/admin/subject/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: subject delete
 * @return {Promise} promise
 * */
 export const deleteSubject = (id) => {
  return server({
    url: `/admin/subject/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: subject copy
 * @param {number} subject_id
 * @param {number} position_id
 * @return {Promise} promise
 * */
 export const copySubject = (data) => {
  return server({
    url: `/admin/subject/copy_examination/`,
    method: 'POST',
    data
  })
}
/**
 * @description: chapter list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} subject
 * @return {Promise} promise
 * */
 export const chapterList = (params) => {
  return server({
    url: `/admin/chapter/`,
    method: 'GET',
    params
  })
}
/**
 * @description: chapter detail
 * @return {Promise} promise
 * */
 export const chapterDetail = (id) => {
  return server({
    url: `/admin/chapter/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: chapter create
 * @param {number} subject subject id
 * @param {string} title
 * @param {number} status 0 1
 * @return {Promise} promise
 * */
 export const createChapter = (data) => {
  return server({
    url: `/admin/chapter/`,
    method: 'POST',
    data
  })
}
/**
 * @description: chapter edit
 * @param {number} subject subject id
 * @param {string} title
 * @param {number} status 0 1
 * @return {Promise} promise
 * */
 export const editChapter = ({id,...data}) => {
  return server({
    url: `/admin/chapter/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: chapter delete
 * @return {Promise} promise
 * */
 export const deleteChapter = (id) => {
  return server({
    url: `/admin/chapter/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: section list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} parent
 * @return {Promise} promise
 * */
 export const sectionList = (params) => {
  return server({
    url: `/admin/section/`,
    method: 'GET',
    params
  })
}
/**
 * @description: section detail
 * @return {Promise} promise
 * */
 export const sectionDetail = (id) => {
  return server({
    url: `/admin/section/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: section create
 * @param {number} subject subject id
 * @param {string} title
 * @param {number} status 0 1
 * @param {number} parent chapter id
 * @return {Promise} promise
 * */
 export const createSection= (data) => {
  return server({
    url: `/admin/section/`,
    method: 'POST',
    data
  })
}
/**
 * @description: section edit
 * @param {number} subject subject id
 * @param {string} title
 * @param {number} status 0 1
 * @param {number} parent chapter id
 * @return {Promise} promise
 * */
 export const editSection = ({id,...data}) => {
  return server({
    url: `/admin/section/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: section delete
 * @return {Promise} promise
 * */
 export const deleteSection = (id) => {
  return server({
    url: `/admin/section/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: examination list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} type 1 2 3
 * @param {number} level 1 2 3
 * @param {number} section
 * @return {Promise} promise
 * */
 export const examinationList = (params) => {
  return server({
    url: `/admin/examination/`,
    method: 'GET',
    params
  })
}
/**
 * @description: examination detail
 * @return {Promise} promise
 * */
 export const examinationDetail = (id, params) => {
  return server({
    url: `/admin/examination/${id}/`,
    method: 'GET',
    params
  })
}
/**
 * @description: examination create
 * @param {number} type 1:O/X    2:desc   3:O/X desc
 * @param {number} level 1:Level1 2:Level2 3:Level3
 * @param {string} title
 * @param {string} content
 * @param {string} explain
 * @param {number} order
 * @param {string} answer
 * @param {number} bool 1  0
 * @param {number} section_id 
 * @param {number} status 1 0
 * @return {Promise} promise
 * */
 export const createExamination= (data) => {
  return server({
    url: `/admin/examination/`,
    method: 'POST',
    data
  })
}
/**
 * @description: examination edit
 * @param {number} type 1:O/X    2:desc   3:O/X number
 * @param {string} level Level1 Level2 Level3
 * @param {string} title
 * @param {string} content
 * @param {string} explain
 * @param {number} order
 * @param {string} answer
 * @param {number} bool 1  0
 * @param {number} chapter 
 * @param {number} status 1 0
 * @return {Promise} promise
 * */
 export const editExamination = ({id,...data}) => {
  return server({
    url: `/admin/examination/${id}/`,
    method: 'PUT',
    data
  })
}
/**
 * @description: examination delete
 * @return {Promise} promise
 * */
 export const deleteExamination = (id) => {
  return server({
    url: `/admin/examination/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: group list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const groupList = (params) => {
  return server({
    url: `/admin/group/`,
    method: 'GET',
    params
  })
}
/**
 * @description: group detail
 * @return {Promise} promise
 * */
 export const groupDetail = (id) => {
  return server({
    url: `/admin/group/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: order list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const orderList = (params) => {
  return server({
    url: `/admin/course/`,
    method: 'GET',
    params
  })
}
/**
 * @description: order detail
 * @return {Promise} promise
 * */
 export const orderDetail = (id) => {
  return server({
    url: `/admin/course/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: order delete
 * @return {Promise} promise
 * */
 export const orderDelete = (id) => {
  return server({
    url: `/admin/course/${id}/`,
    method: 'DELETE',
  })
}
/**
 * @description: personal_management
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const personalManagement = (params) => {
  return server({
    url: `/admin/personal_management/`,
    method: 'GET',
    params
  })
}
/**
 * @description: personal_management_detail
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @param {number} id
 * @return {Promise} promise
 * */
 export const personalManagementDetail = (params) => {
  return server({
    url: `/admin/personal_management_detail/`,
    method: 'GET',
    params
  })
}
/**
 * @description: personal exam list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const personalExamList = (params) => {
  return server({
    url: `/admin/userexam/`,
    method: 'GET',
    params
  })
}
/**
 * @description: personal exam detail
 * @return {Promise} promise
 * */
 export const personalExamDetail = (id) => {
  return server({
    url: `/admin/userexam/${id}/`,
    method: 'GET',
  })
}
/**
 * @description: group exam list
 * @param {number} page
 * @param {number} page_size
 * @param {string} search
 * @param {string} ordering
 * @return {Promise} promise
 * */
 export const groupExamList = (params) => {
  return server({
    url: `/admin/groupexam/`,
    method: 'GET',
    params
  })
}
/**
 * @description: group exam detail
 * @return {Promise} promise
 * */
 export const groupExamDetail = (id) => {
  return server({
    url: `/admin/groupexam/${id}/`,
    method: 'GET',
  })
}