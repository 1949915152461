import Vue from '@/main'
import axios from 'axios'

import { Message } from 'element-ui';
import baseURL from './baseUrl';
import storage from '@/utils/storage'
import Load from "./loading"
import store from '../store';

const server = axios.create({
  baseURL: baseURL.DOMIN,
  timeout: 50000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'kr;q=0.9'
  }
})

server.interceptors.request.use(config => {
  const token = storage.getLocal('token')
  if (token) {
    config.headers.Authorization = 'token=' + token
  }
  Load.showLoading()
  return config
}, err => {
  Message.error({ message: '요청 시간 초과, 다시 시도해 보세요.' });
  Load.hideLoading()
  return Promise.reject(err)
})

server.interceptors.response.use(res => {
  Load.hideLoading()
  // console.log(res.data)
  if (res.data.code) {
    Message.error({ message: res.data.msg });
    return Promise.reject(res.data)
  }
  return res.data
}, err => {
  // console.log(err)
  Load.hideLoading()
  if (err.response && (err.response.status === 401||err.response.status === 403)) {
    Vue.$router.push('/login')
    storage.removeLocal('userinfo')
    storage.removeLocal('token')
    store.state.routes = null
    Vue.$message.error("로그인 시간 초과하였습니다. 다시 로그인해 주세요.")
    return Promise.reject(err)
  }
  Vue.$message.error('응답 오류, 다시 시도해 보세요.')
  return Promise.reject(err)
})


export default server
