
const server = {
    pro: "",
    dev: "",
    util: "",
  }
  // http://192.168.3.14:8000/   http://3.36.123.152:8000 http://192.168.3.161/ http://api.tongtongedu.com/
  // var DOMIN = "https://api.tongtongedu.com/";
  var DOMIN = "https://api.ttedu.kr/";
  const origin = location.hostname
  for (var i in server) {
    var item = server[i]
    if (item.indexOf(origin) > -1) {
      DOMIN = item
    }
  }
 
  if (!DOMIN) DOMIN = "/api"
  export default {
    DOMIN
  }
  